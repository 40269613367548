<template>
  <v-card>
    <v-card-text>
      <FeatureSwitch :features="features" @update="updateFeature" />
    </v-card-text>
  </v-card>
</template>
<script>
import { set } from "lodash";
import { mapActions } from "vuex";
import FeatureSwitch from "@/views/administration/FeatureFlags/FeatureSwitches/FeatureSwitches.vue";

export default {
  name: "SettingsCategory",
  props: {
    features: {},
    definition: {
      type: Object,
    },
  },
  components: {
    FeatureSwitch,
  },
  methods: {
    ...mapActions(["saveSettingsToDomain"]),
    updateFeature({ name, value }) {
      const settings = {};
      set(settings, name, value);
      this.saveSettingsToDomain(settings);
    },
  },
};
</script>
